body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  background-color: var(--background-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.right-fixed-component {
  position: fixed;
  bottom: 50px;
  right: 20px;
}

.right-fixed-component:hover {
  color: inherit;
}

.icon-light {
  margin-right: 15px;
  margin-left: 15px;
}

.icon-dark {
  margin-right: 15px;
  margin-left: 15px;
}

a:visited {
  color: inherit;
}

a:hover {
  color: rgb(255, 255, 255);
}

.pad-top {
  padding-top: 30px;
  padding-bottom: 30px;
}

.icon-light:hover {
  color: #BBDDE9;
}

.icon-dark:hover {
  color: rgb(255, 255, 255);
}


.portfoliodiv {
  font-family: Menlo, monospace;
  padding-top: 30px;
}

.non-rb-button-light {
  position: relative;
  z-index: 1 !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6) !important;
  padding: 6px 12px !important;
  border-radius: 2px !important;
  font-size: 16px !important;
  background-color: #BBDDE9 !important;
  border-color: #BBDDE9 !important;
  color: black !important;
}

.non-rb-button-light:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.8) !important;
  background-color: #84B9CC !important;
  color: black !important;
  border-color: #84B9CC !important;
}

.non-rb-button-dark {
  position: relative;
  z-index: 1 !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6) !important;
  padding: 6px 12px !important;
  border-radius: 2px !important;
  font-size: 16px !important;
  background-color: rgb(178, 176, 176) !important;
  border-color: black !important;
  color: #272626 !important;
}

.non-rb-button-dark:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.8) !important;
  background-color: rgb(255, 255, 255) !important;
  color: black !important;
}

.basic-text-light {
  color: rgb(40, 39, 39) !important;
  font-family: Menlo, monospace;
}

.basic-text-light:hover {
  color: #BBDDE9 !important;
}

.basic-text-dark {
  color: #dddddd !important;
  font-family: Menlo, monospace;
}

.basic-text-dark:hover {
  color: rgb(255, 255, 255) !important;
}

.form-shadow-light {
  z-index: 1 !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6) !important;
}

.form-shadow-dark {
  z-index: 1 !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6) !important;
  background-color: rgb(186, 186, 186) !important;
}

.light-card {
  background-color: #ddeef4;
  color: rgb(40, 39, 39) !important;
}

.dark-card {
  background-color: #235b6e;
  color: #dddddd !important;
}