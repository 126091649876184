.lightapp {
  text-align: center;
  background-color: #F6F7FB;
  
}

.lightapp-header {
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(40, 39, 39);
}

.lightapp-link {
  color: rgb(40, 39, 39);
}

.darkapp {
  text-align: center;
  background-color: #194250;
}

.darkapp-header {
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #dddddd;
}

.darkapp-link {
  color: #688a94;
}
